import styled from 'styled-components'

export const Body = styled.div`
  max-width: 100vw;
  padding: 16px;
  padding-top: 5px;
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  flex-wrap: ${({ wrap }) => wrap ? 'wrap' : 'nowrap'};
`
