import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Body } from './styles.js'
import DrugstoreInfo from '@components/DrugstoreInfo'
import PulsingCircle from '@components/Icons/PulsingCircle'
import Clock from '@components/Icons/Clock'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import DolarSign from '@components/Icons/DolarSign'
import Pin from '@components/Icons/Pin'
import Card from '@components/Icons/Card'
import { Grid, Col, Row, } from 'react-flexbox-grid'
import moment from 'moment'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { minutesToTime } from '../../helpers/DateConversion'
import { Creators } from '../../actions'
import './index.css'
import GAEvents from '../../helpers/GAEvents.js'

const WEEK_DAY = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
  'Dom',
  'Fer'
]

class BodyContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingDeliveries: false,
      storeOpen: true,
      deliverySchedule: [],
      paymentMethods: []
    }
  }

  componentDidMount() {
    const { attemptGetDeliverySchedule, attemptGetDeliveryCities, attemptGetDeliveryNeighborhoods, attemptGetPaymentMethods } = this.props

    attemptGetDeliverySchedule()
    attemptGetDeliveryCities()
    attemptGetDeliveryNeighborhoods()
    attemptGetPaymentMethods()
  }

  componentWillReceiveProps(newProps) {
    const { _delivery, _payment, _address } = newProps
    let newState = Object.assign({}, this.state)

    // Delivery schedule loaded
    if (!_delivery.fetching && _delivery.schedule !== null) {
      const { schedule } = _delivery
      const now = moment()
      let storeOpen = false
 
      newState.deliverySchedule = schedule

      schedule.forEach(p => {
        if (Number(p.day) === now.isoWeekday()) {
          const timeStart = moment(p.start, 'HH:mm:ss')
          const timeEnd = moment(p.end, 'HH:mm:ss')
          
          if (now.unix() > timeStart.unix() && now.unix() < timeEnd.unix()) {
            storeOpen = true
          }
        }
      })

      if (storeOpen !== newState.storeOpen) {
        newState.storeOpen= storeOpen
      }
    }

    // Payment methods loaded
    if (!_payment.fetching && _payment.paymentMethods !== null) {
      newState.paymentMethods = _payment.paymentMethods
    }

    // Load deliveries
    if (newState.loadingDeliveries === false && _delivery.deliveries === null) {
      const { currentAddress } = _address
      let neighborhoodId = null

      if (currentAddress) {
        const { neighborhood } = currentAddress
        neighborhoodId = neighborhood && typeof neighborhood === 'object' ? neighborhood.neighborhood_id : null
      }

      this.props.attemptGetDeliveries(neighborhoodId)
      this.props.attempGetRegionsDelivery()
      newState.loadingDeliveries = true
    }

    // Update state if has changed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  _renderScheduleDropdownContent = () => {
    const { deliverySchedule } = this.state
    
    return (
      <Grid className="schedule">
        {
          deliverySchedule.map((schedule, index) => {
            const { day, start, end } = schedule

            const startTime = moment(start, 'HH:mm:ss')
            const endTime = moment(end, 'HH:mm:ss')
            const exactDay = Number(day - 1)

            let startStr = `${startTime.format('HH')}h`
            let endStr = `${endTime.format('HH')}h`
            
            if (startTime.minutes() > 0) {
              startStr += startTime.format('mm')
            }
            if (endTime.minutes() > 0) {
              endStr += endTime.format('mm')
            }

            return (
              <Row key={index}>
                <Col xs={3} className="primary-text">
                  <strong>{WEEK_DAY[exactDay]}.</strong>
                </Col>
                <Col xs={8} xsOffset={1} className="secondary-text" style={{fontSize: 14}}> {`${startStr} às ${endStr}`}</Col>
              </Row>
            )
          })
        }

        <Row>
          <Col xs={12} className="observation">
            <strong className="primary-text">OBS: </strong>pedidos feitos fora do horário de entrega serão entregues no próximo dia de entrega,
            conforme lista acima. 
          </Col>
        </Row>
      </Grid>
    )
  }

  _renderDeliveredRegions = () => {
    const { _delivery, _address } = this.props
    const { cities, regions, deliveries } = _delivery
    const { currentAddress } = _address;
    let address1 = null;
    let deliverable = false
   
    if (currentAddress && deliveries) {
      const { neighborhood, not_deliverable } = currentAddress;

      address1 = neighborhood.neighborhood_id;
      deliverable = not_deliverable
    }
 
    if (cities && regions) {
      return (
        <Grid className="regions">
          {
            cities.map((city, index) => {
              const { name, zone, neighborhoods } = city

              return (
                <Row key={index}>
                  <Col xs={2}>
                    <Row center="xs">
                      <Col xs={12}>
                        <Pin width={20} height={20} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={10}>
                    <Row>
                      <Col xs={12} className="primary-text">
                        <strong>{`${name} - ${zone.code}`}</strong>
                      </Col>
                    </Row>
                    {
                      neighborhoods.sort(function(a, b) {
                        if (a.neighborhood_id === address1 && !deliverable) {
                          return -1
                        }
                        return 0
                      }).map((neighborhood, idx) => {
                        const { neighborhood_id } = neighborhood
                        const delivery = regions.find(x => x.neighborhood_id === neighborhood_id)
                                          
                        if (delivery) {
                          const { delivery_fee } = delivery
                         
                          return(
                            <Row key={idx}>
                              <Col xs={12} className="secondary-text" style={{ color: (neighborhood_id === address1 && !deliverable) ? "#333" : "unset", fontWeight: (neighborhood_id === address1 && !deliverable) ? "bold": "unset" }}>
                                {neighborhood.name}
                                <p style={{lineHeight: 0, marginLeft: 10, fontWeight: 600}}>
                                  {`• ${floatToBRL(Number(delivery_fee))}`}
                                </p>
                              </Col>
                            </Row>
                          )
                        }

                        return null
                      })
                    }
                  </Col>
                </Row>
              )
            })
          }
        </Grid>
      )
    }
  }

  _renderPaymentMethods = () => {
    const { paymentMethods } = this.state
    
    // Parse our list
    const methods = []
    paymentMethods.forEach(method => {
      const { name, type } = method

      const index = methods.findIndex(p => p.name === name)
      const convens = methods.filter(p => p.types.indexOf('conven') !== -1)
      
      if (index === -1) {
        // Avoid multiples covenants
        if (type !== 'conven' || convens.length === 0) {
          methods.push({
            name: name,
            types: [type]
          })
        }
      } else {
        methods[index].types.push(type)
      }
    })

    return (
      <Grid className="regions">
        {
          methods.map((method, index) => {
            const { name, types } = method
            let _name = name

            let _type = types[0]
            if (types.indexOf('credit') !== -1 || types.indexOf('debit') !== -1) {
              _type = types.indexOf('credit') ? 'Cred.' : 'Deb.'
              if (types.length > 1) {
                _type += ` e ${types[types.indexOf('debit')] !== -1 ? 'Deb' : ''}`
              }
            }

            // Pagseguro fix
            if (name === 'pagseguro') {
              _name = 'PagSeguro'
            }

            if (types.indexOf('gateway') !== -1) {
              _type = 'Pagamento Online'
            }

            return (
              <Row middle="xs" key={index} style={{marginBottom: 5}}>
                <Col xs={3}>
                  {_type === 'money' ?  <DolarSign width={25} height={25} /> : <Card width={20} height={20} />}
                </Col>
                <Col xs={9}>
                  <p className="secondary-text" style={{lineHeight: 1, margin: 0}}>
                    {_type === 'conven' ? 'Convênios' : _name}
                  </p>

                  {_type !== 'money' && _type !== 'conven' ? <strong className="primary-text">{_type}</strong> : null}
                </Col>
              </Row>
            )
          })
        }
      </Grid>
    )
  }

  render() {
    const { children, _delivery } = this.props
    const { deliveries } = _delivery
    const { deliverySchedule, storeOpen } = this.state

    let deliveryTime = '0 - 0 min'
    if (deliveries !== null && deliveries.length > 0) {
      deliveries.sort(function(a, b) {
        return a.delivery_time - b.delivery_time 
      })
      const { delivery_time } = deliveries[0]
      const { value, suffix } = minutesToTime(delivery_time)
      const offset = suffix === 'mins' ? 20 : 1

      deliveryTime = `${value} - ${value + offset} ${suffix}`
    }

    let scheduleTime = ''
    let now = moment()
    deliverySchedule.forEach(schedule => {
      const { day, start, end } = schedule
      
      if (Number(day) === now.isoWeekday()) {
        const startTime = moment(start, 'HH:mm:ss')
        const endTime = moment(end, 'HH:mm:ss')

        let startStr = `${startTime.format('HH:mm')}h`
        let endStr = `${endTime.format('HH:mm')}h`

        scheduleTime = `Hoje: ${startStr} às ${endStr}`
      }
    })

    return (
      <React.Fragment>
        <Body>
          <DrugstoreInfo
            icon={<PulsingCircle open={storeOpen} />}
            primaryText="Ver horários de entrega"
            secondaryText={scheduleTime}
            onClick={()=> GAEvents({ category: 'Navigation', action: 'Click no botão Ver horarios de entrega',label : 'BodyContainer' })}
            dropdown
          >
            {this._renderScheduleDropdownContent()}
          </DrugstoreInfo>
          <DrugstoreInfo
            icon={<Clock />}
            primaryText="Tempo de entrega"
            secondaryText={deliveryTime}
          />
          <DrugstoreInfo
            icon={<DeliveryBoy />}
            primaryText="Ver regiões de entrega"
            onClick={()=> GAEvents({ category: 'Navigation', action: 'Click no botão Ver regioes de entrega',label : 'BodyContainer' })}
            dropdown
          >
            {this._renderDeliveredRegions()}
          </DrugstoreInfo>
          <DrugstoreInfo
            icon={<DolarSign />}
            primaryText="Ver formas de pagamento"
            onClick={()=> GAEvents({ category: 'Navigation', action: 'Click no botão Ver formas de pagamento',label : 'BodyContainer' })}
            dropdown
          >
            {this._renderPaymentMethods()}
          </DrugstoreInfo>
        </Body>
        <Body column style={{marginTop: 40}}>
          {children}
        </Body>

        <ToastContainer />
      </React.Fragment >
    )
  }
}

const mapStateToProps = ({ delivery, payment, address }) => ({
  _delivery: delivery,
  _payment: payment,
  _address: address,
  _regions: delivery.regions
})

const mapDispatchToProps = dispatch => ({
  attemptGetDeliverySchedule: () => dispatch(Creators.deliveryScheduleRequest()),
  attemptGetDeliveryCities: () => dispatch(Creators.cityListRequest()),
  attemptGetDeliveryNeighborhoods: () => dispatch(Creators.neighborhoodListRequest()),
  attemptGetDeliveries: (neighborhood_id) => dispatch(Creators.deliveryListRequest(neighborhood_id)),
  attemptGetPaymentMethods: () => dispatch(Creators.paymentMethodListRequest()),

  attempGetRegionsDelivery: (neighborhood_id) => dispatch(Creators.regionsListRequest(neighborhood_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer)
