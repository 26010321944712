import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.searchEngineApi
})

const searchProducts = ({ fingerprint, store, query }) => api.post('v1/search', { fingerprint, store, query })
const categoryProducts = ({ store_id, category_id, pmc_id, params }) => api.get(`v1/category/${store_id}/${category_id}/${pmc_id}`, params)

export default {
  searchProducts,
  categoryProducts
}
